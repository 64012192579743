import "./App.css";
import Footer from "./component/Footer";
import Header from "./component/Header";
// import { RESUME_DATA } from "./component/resume-data";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Services from "./pages/Services";
import Skills from "./pages/Skills";
import Resume from "./pages/resume";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        {/* <Routes>
          <Route path="/Resume" element={<Resume />} />
        </Routes> */}
        <Header />

        {/* Define the new route */}
        <Home />
        <Services />
        <Skills />
        <Portfolio />
        <Contact />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
