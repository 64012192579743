import React, { useState } from "react";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);

    const formData = new FormData();

    formData.append("Name", name);
    formData.append("Email", email);
    formData.append("Subject", subject);
    formData.append("Message", message);
    // Convert formData to an object for logging
    const formDataObject = Array.from(formData.entries()).reduce(
      (obj, [key, value]) => {
        obj[key] = value;
        return obj;
      },
      {}
    );

    fetch("https://getform.io/f/zaxdmgya", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        // console.log(
        //   "FormData sent:",
        //   formDataObject,
        //   "Response of form API:",
        //   data
        // );
        setSuccess(true); // Show success message

        setTimeout(() => setSuccess(false), 2000); // Hide after 15 seconds
        setSubmitting(false);
        // Clear form inputs
        // Reset state for input fields
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
      })
      .catch((error) => {
        setError(error.message);
        setSubmitting(false);
      });
  };

  return (
    <>
      <section
        data-scroll-index="4"
        id="contactus"
        className="section contactus-section bg-dark"
      >
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-6">
              <div className="contact-form  bg-2">
                <h6>Get in touch</h6>
                <p className="lead">
                  Our friendly team would love to hear from you.
                </p>
                <form id="contact-form" onSubmit={handleSubmit}>
                  <div className="row gx-3 gy-4">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Full name</label>
                        <input
                          type="text"
                          name="Name"
                          required
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name *"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Your Email</label>
                        <input
                          type="email"
                          required
                          name="Email"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                          placeholder="Email *"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">Subject</label>
                        <input
                          required
                          type="text"
                          name="Subject"
                          value={subject}
                          onChange={(event) => setSubject(event.target.value)}
                          placeholder="Subject *"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">Your message</label>
                        <textarea
                          name="Message"
                          required
                          value={message}
                          onChange={(event) => setMessage(event.target.value)}
                          placeholder="Your message *"
                          rows="4"
                          className="form-control"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="send">
                        <button
                          className="px-btn px-btn-theme"
                          type="submit"
                          disabled={submitting}
                        >
                          {submitting ? "Sending..." : "Send Message"}
                        </button>
                        {success && (
                          <p className="text-success">
                            Message sent successfully!
                          </p>
                        )}
                        {error && <p className="text-danger">{error}</p>}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5 ms-auto col-xl-4">
              <ul className="contact-infos">
                <li>
                  <div className="icon bg-1">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                  </div>
                  <div className="col">
                    <h5 className="text-black">Mail</h5>
                    <p className="text-black">Kasimdev07@gmail.com</p>
                  </div>
                </li>
                <li>
                  <div className="icon bg-3">
                    <i className="fas fa-map-marker-alt"></i>
                  </div>
                  <div className="col">
                    <h3 className="">Location</h3>
                    <p className="text-black">Toronto, ON, Canada</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Contact;
