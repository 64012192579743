import React from "react";
import Main from "./Main";

const Home = () => {
  return (
    <>
      <main className="wrapper">
        <Main />
      </main>
    </>
  );
};

export default Home;
