import React from "react";

const Portfolio = () => {
  return (
    <>
      <section className="py-5 bg-dark">
        <div className="container">
          <div className="row gy-3">
            <div className="col-lg-8 col-md-7 text-center text-md-start">
              <h4 className="h1 m-0 text-white">Intrested working with me?</h4>
            </div>
            <div className="col-lg-4 col-md-5 text-center text-md-end">
              <a
                className="px-btn px-btn-theme2"
                data-scroll-nav="4"
                href="#contactus"
              >
                Contact Now
              </a>
            </div>
          </div>
        </div>
      </section>
      <section
        data-scroll-index="3"
        className="section work-section bg-gray text-start"
        id="portfolio"
      >
        <div className="container">
          <div className="row section-heading justify-content-center">
            <div className="col-lg-6 text-center">
              <h3>
                <span>Latest Projects</span>
              </h3>
            </div>
          </div>
          <div className="lightbox-gallery portfolio-box">
            <div className="row gx-3 pb-3 mb-5 pb-lg-3 gy-4 portfolio-box justify-content-around align-items-center">
              <div className="col-md-6 col-lg-5">
                <div className="portfolio-img">
                  <img
                    src="./assets/images/MSD Logo.png"
                    title="MSD Banking App XD"
                    alt="MSD Banking App XD"
                  />
                  <a
                    href="./assets/images/MSD Logo.png"
                    className="gallery-link gallery-link-icon"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                <div className="portfolio-text">
                  <h6>
                    <span>UI/UX - Adobe XD</span>
                  </h6>
                  <h4>MSD Mobile Banking App UI Experience</h4>
                  <p>
                    MSD mobile banking app provides a holistic financial
                    solution with an intuitive interface and robust security
                    measures, inspired by industry leaders like CIBC and HDFC.
                    The app features a centralized dashboard, detailed
                    transaction records, investment tracking, secure money
                    transfer capabilities, and biometric authentication for
                    secure login. With a focus on user experience and security,
                    our app aims to deliver a comprehensive, secure, and
                    user-friendly banking experience.
                  </p>
                  <p>
                    <b>Key Features:</b> Centralized dashboard, Detailed
                    transaction records, Investment tracking, Secure money
                    transfer, Biometric authentication.
                    <p>
                      <b>Design Principles:</b> User experience and security
                      prioritization Clarity, consistency, and simplicity in
                      design Use of metaphors and design patterns for intuitive
                      navigation
                    </p>
                  </p>
                  <div>
                    <h5>
                      Tools of choice :{" "}
                      <strong className="fs-6 text-black-50">
                        Adobe XD, Illustrator, Figma
                      </strong>
                    </h5>
                  </div>
                  <div className="btn-bar">
                    <a
                      className="px-btn px-btn-theme"
                      data-scroll-nav="4"
                      href="https://xd.adobe.com/view/74b36ad1-bb5c-445a-b45e-d3687a673550-21e0/grid/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Project
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row gx-3 pb-3 mb-5 pb-lg-3 gy-4 portfolio-box justify-content-around align-items-center flex-row-reverse">
              <div className="col-md-6 col-lg-5">
                <div className="portfolio-img">
                  <img
                    src="./assets/images/book-buddy.png"
                    title="Book Buddy "
                    alt="Book Buddy "
                  />
                  <a
                    href="./assets/images/book-buddy.png"
                    className="gallery-link gallery-link-icon"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                <div className="portfolio-text">
                  <h6>
                    <span>Next.JS + Tailwind + Shadcn/ui</span>
                  </h6>
                  <h4> Book Buddy</h4>
                  <p>
                    This website is a comprehensive online platform designed to
                    bring book lovers together, providing a unique and engaging
                    experience for readers and traders alike. The website
                    combines the best aspects of social media, online
                    marketplaces, and community forums to create a vibrant
                    ecosystem where users can connect, share, and trade books.
                    With a focus on user experience and security, our website
                    offers a range of innovative features that cater to the
                    diverse needs of book enthusiasts. From discovering new
                    titles and authors to trading books with like-minded
                    individuals, our website is the ultimate destination for
                    anyone who loves to read.
                  </p>
                  <p>
                    <b> Key Features: </b>
                    Social media platform for book lovers to connect and
                    interact, Detailed book library with user reviews and
                    ratings Secure and localized book trading system Interactive
                    book clubs with discussion forums and live chat Personalized
                    recommendations and alerts
                  </p>
                  <div>
                    <h5>
                      Tools of choice :{" "}
                      <strong className="fs-6 text-black-50">
                        VS Code, Next.js, Shadcn, Tailwind
                      </strong>
                    </h5>
                  </div>
                  <div className="btn-bar">
                    <a
                      className="px-btn px-btn-theme"
                      data-scroll-nav="4"
                      target="_blank"
                      rel="noreferrer"
                      href="https://bookbuddy-nextjs.vercel.app/"
                    >
                      View Project
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row gx-3 pb-3 mb-5 pb-lg-3 gy-4 portfolio-box justify-content-around align-items-center">
              <div className="col-md-6 col-lg-5">
                <div className="portfolio-img">
                  <img src="./assets/images/work-1.png" title="" alt="" />
                  <a
                    href="./assets/images/work-1.png"
                    className="gallery-link gallery-link-icon"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                <div className="portfolio-text">
                  <h6>
                    <span>Html + CSS + JS</span>
                  </h6>
                  <h4>Lorem ipsum dolor sit amet,</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>

                  <div className="btn-bar">
                    <a
                      className="px-btn px-btn-theme"
                      data-scroll-nav="4"
                      href="https://kaya-perfume-react-7u7t8v87y-kasimkazmis-projects.vercel.app/"
                      // target="_blank"
                    >
                      View Project
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row gx-3 pb-3 mb-5 pb-lg-3 gy-4 portfolio-box justify-content-around align-items-center flex-row-reverse">
              <div className="col-md-6 col-lg-5">
                <div className="portfolio-img">
                  <img
                    src="./assets/images/work-1.png"
                    title="Job Search website"
                    alt="Job Search website"
                  />
                  <a
                    href="./assets/images/work-1.png"
                    className="gallery-link gallery-link-icon"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                <div className="portfolio-text">
                  <h6>
                    <span>React + Tailwind CSS</span>
                  </h6>
                  <h4>Lorem ipsum dolor sit amet,</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>

                  <div className="btn-bar">
                    <a
                      className="px-btn px-btn-theme"
                      data-scroll-nav="4"
                      href="https://www.kasimkazmi.me/"
                      // target="_blank"
                    >
                      View Project
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
